import React from 'react';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const Layout = ({ children }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });

  return (
    <div className={`layout ${isDesktopOrLaptop ? 'desktop' : 'mobile'}`}>
      {children}
      <Outlet />
    </div>
  );
};

export default Layout;