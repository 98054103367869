import React from "react";
import * as ReactDomClient from 'react-dom/client';
import App from './App';

// Находим элемент с id "app", в который будет рендериться приложение
const app = ReactDomClient.createRoot(document.getElementById("app"));

// Рендерим компонент App
app.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
