import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';


const DesktopComponent = React.lazy(() => import('./DesktopComponent'));
const MobileComponent = React.lazy(() => import('./MobileComponent'));

const App = () => {
  const [isDesktopOrLaptop, setIsDesktopOrLaptop] = useState(window.innerWidth >= 1224);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktopOrLaptop(window.innerWidth >= 1224);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <Layout isDesktopOrLaptop={isDesktopOrLaptop}>
        <Suspense>
          {isDesktopOrLaptop ? <DesktopComponent /> : <MobileComponent />}
        </Suspense>
      </Layout>
    </Router>
  );
};

export default App;